"use strict";

//theme_original
import themeOriginal from './theme_original/wabisabi';
/**
 * JS
 */
//common
import common from './modules/common';
//swiper
import {
    topMvSwiper,
    mediaSwiper
} from './modules/my-swiper';
//modaal
import modaal from './modules/modaal';
import search from './modules/search';
jQuery(function($) {

    $(function() {
        common();
        topMvSwiper();
        mediaSwiper();
        modaal();
        search();
    })
});

/**
 * CSS
 */
// import styles bundle
import '../scss/omuro/top.scss';