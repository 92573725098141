"use strict";

import ModaalJs from 'modaal/dist/js/modaal.min.js';
import ModaalCss from 'modaal/dist/css/modaal.min.css';

export default function main() {
    $('.m_video').modaal({
        type: 'video',
    });
    $(".jsModaalImg").modaal({
        type: 'image'
    });
    $(".jsDetail").modaal({
        content_source: '.wrapping_type'
    });
}