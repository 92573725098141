"use strict";


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

export function topMvSwiper() {
    /**
     * mv
     */
    const topMvSwiper = new Swiper("#topMvSwiper", {
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        loop: true,
        loopAdditionalSlides: 1,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        pagination: {
            el: '#topMvSwiperPagination',
            clickable: true,
        },
    });
}
export function mediaSwiper() {
    /**
     * media
     */
    const mediaSwiper = new Swiper("#mediaSwiper", {
        slidesPerView: 2.2,
        spaceBetween: 20,
        loop: true,
        speed: 2000,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 3.5,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 40,
                centeredSlides: false,
            },
        },
    });
}
export function detailSwiper() {
    /**
     * detail
     */
    var detailSwiperThumbs = new Swiper("#detailSwiperThumbs", {
        loop: true,
        spaceBetween: 12,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: "#detailSwiperThumbsNext",
            prevEl: "#detailSwiperThumbsPrev",
        },

    });
    var detailSwiperMain = new Swiper("#detailSwiperMain", {
        spaceBetween: 10,
        loop: true,
        loopAdditionalSlides: 1,
        speed: 2000,
        watchSlidesProgress: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        thumbs: {
            swiper: detailSwiperThumbs,
        },
        pagination: {
            el: '#detailSwiperMainPagination',
            clickable: true,
        },
    });
}

export function seriesSwiper() {
    /**
     * seriesSwiper
     */
    const seriesSwiper = new Swiper("#seriesSwiper", {
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        pagination: {
            el: '#seriesSwiperPagination',
            clickable: true,
        },
        watchOverflow: true,
    });
}